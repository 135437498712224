import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-announcement-shared-modal',
  templateUrl: './announcement-shared-modal.component.html',
  styleUrls: ['./announcement-shared-modal.component.scss'],
})
export class AnnouncementSharedModalComponent {
  constructor(
    public dialogRef: MatDialogRef<AnnouncementSharedModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  close() {
    this.dialogRef.close();
    localStorage.setItem('showAnnouncement', '1');
  }
}
