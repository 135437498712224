// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// for development environment
// This is QA branch
export const environment = {
  production: false,
  hmr: false,
  name: 'dev',
  apiPath: '/api',
  pusherKey: 'c510c8fda04b95a0f388',
  pusherCluster: 'ap1',
  versionCheckURL: 'https://dev.simpliedtech.com/version.json',
  baseUrl: 'https://dev.srv.simpliedtech.com',
  apiHostName: 'https://dev.srv.simpliedtech.com',
  apiBaseUrl: 'https://dev.srv.simpliedtech.com/api',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
