import { BehaviorSubject, Observable } from 'rxjs';
import { GenericService } from '../generic/generic.service';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class StudentUserService {
  private readonly controller = 'student';
  private initialState = {
    data: [],
  };
  private classes$: BehaviorSubject<any> = new BehaviorSubject<any>(
    this.initialState,
  );
  private dataLoading = false;

  constructor(private generic: GenericService) {}

  getAttendance(params) {
    const order = 'asc';
    return this.generic.getDataByParams(
      `${this.controller}/attendance`,
      params,
      order,
    );
  }

  getTimetable() {
    return this.generic.getData(`${this.controller}/timetable`);
  }

  getClasses(): Observable<any> {
    if (
      this.classes$.getValue() &&
      this.classes$.getValue() !== this.initialState
    ) {
      return this.classes$.asObservable();
    } else if (!this.dataLoading) {
      return this.set();
    } else {
      return this.classes$.asObservable();
    }
  }

  private set(): Observable<any> {
    this.dataLoading = true;
    return this.generic.getData(`${this.controller}/classes`).pipe(
      tap((response) => {
        this.classes$.next(response);
        this.dataLoading = false;
      }),
    );
  }

  getClassById(classId) {
    return this.generic.getDataById(`${this.controller}/classes`, classId);
  }

  getStream(classId, params, order = 'desc', sort = 'createdAt') {
    return this.generic.getDataByParams(
      `${this.controller}/classes/${classId}/stream`,
      params,
      order,
      sort,
    );
  }

  addReaction(classId, streamId, body) {
    return this.generic.create(
      `${this.controller}/classes/${classId}/stream/${streamId}/reaction`,
      body,
    );
  }

  getClassWork(classId) {
    return this.generic.getData(
      `${this.controller}/classes/${classId}/classwork`,
    );
  }

  getByIdClassAssignment(classId, assignmentId) {
    return this.generic.getDataById(
      `${this.controller}/classes/${classId}/assignment`,
      assignmentId,
    );
  }

  getByIdMaterial(classId, materialId) {
    return this.generic.getDataById(
      `${this.controller}/classes/${classId}/material`,
      materialId,
    );
  }

  uploadAnswerDocument(classId, assignmentId, questionId, body) {
    return this.generic.imageUpload(
      `${this.controller}/classes/${classId}/assignment/${assignmentId}/question/${questionId}/answer/upload-attachment`,
      body,
    );
  }

  deleteAnswerDocument(classId, assignmentId, questionId, url) {
    return this.generic.deleteWithOutId(
      `${this.controller}/classes/${classId}/assignment/${assignmentId}/question/${questionId}/answer/delete-attachment?fileUrl=${url}`,
    );
  }

  submitAssignment(classId, assignmentId, body) {
    return this.generic.create(
      `${this.controller}/classes/${classId}/assignment/${assignmentId}/submit`,
      body,
    );
  }

  getClassSettings(classId) {
    return this.generic.getData(
      `${this.controller}/classes/${classId}/class-setting`,
    );
  }

  getClassGradebook(classId, assignTerm) {
    const params = `?assignTerm=${assignTerm}`;
    return this.generic.getData(
      `${this.controller}/classes/${classId}/gradebook${params}`,
    );
  }

  getGradebook(assignTermId) {
    const query = `?assignTerm=${assignTermId}`;
    return this.generic.getData(`${this.controller}/gradebook${query}`);
  }

  getAssignTerms(academicYearId) {
    return this.generic.getData(
      `${this.controller}/assignterms/${academicYearId}`,
    );
  }
}
