import { BehaviorSubject, Observable } from 'rxjs';
import { GenericService } from '../../generic/generic.service';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ParentUserService {
  private initialState = {
    data: [],
    studentId: '',
  };
  private classes$: BehaviorSubject<any> = new BehaviorSubject<any>(
    this.initialState,
  );
  private dataLoading = false;
  controller = 'parent';

  constructor(private generic: GenericService) {}

  get() {
    return this.generic.getData(this.controller);
  }

  post(body) {
    return this.generic.create(this.controller, body);
  }

  getByID(id) {
    return this.generic.getDataById(this.controller, id);
  }

  delete(id) {
    return this.generic.delete(this.controller, id);
  }

  update(body) {
    return this.generic.update(this.controller, body);
  }

  getbyParams(params) {
    const order = 'desc';
    return this.generic.getDataByParams(this.controller, params, order);
  }

  search(search, filter, order) {
    return this.generic.getDataBySearch(this.controller, search, filter, order);
  }

  dueDateExtension(body) {
    return this.generic.update(
      this.controller + '/dueDateExtension/request',
      body,
    );
  }
  getGmailCreditionals(stdID) {
    return this.generic.getData(`${this.controller}/credentials/${stdID}`);
  }

  getClasses(studentId): Observable<any> {
    if (
      this.classes$.getValue() &&
      this.classes$.getValue() !== this.initialState &&
      studentId == this.classes$.getValue().studentId
    ) {
      return this.classes$.asObservable();
    } else if (!this.dataLoading) {
      return this.set(studentId);
    } else {
      return this.classes$.asObservable();
    }
  }

  private set(studentId): Observable<any> {
    this.dataLoading = true;
    return this.generic
      .getData(`${this.controller}/classes/student/${studentId}`)
      .pipe(
        tap((response) => {
          const newResponse = { ...response, studentId };
          this.classes$.next(newResponse);
          this.dataLoading = false;
        }),
      );
  }

  getClassById(classId) {
    return this.generic.getDataById(`${this.controller}/classes`, classId);
  }

  getStream(classId, params, order = 'desc', sort = 'createdAt') {
    return this.generic.getDataByParams(
      `${this.controller}/classes/${classId}/stream`,
      params,
      order,
      sort,
    );
  }

  addReaction(classId, streamId, body) {
    return this.generic.create(
      `${this.controller}/classes/${classId}/stream/${streamId}/reaction`,
      body,
    );
  }

  getClassWork(classId) {
    return this.generic.getData(
      `${this.controller}/classes/${classId}/classwork`,
    );
  }

  getByIdClassAssignment(classId, assignmentId, studentId) {
    return this.generic.getData(
      `${this.controller}/classes/${classId}/assignment/${assignmentId}?studentId=${studentId}`,
    );
  }

  getByIdMaterial(classId, materialId) {
    return this.generic.getDataById(
      `${this.controller}/classes/${classId}/material`,
      materialId,
    );
  }

  uploadAnswerDocument(classId, assignmentId, questionId, body) {
    return this.generic.imageUpload(
      `${this.controller}/classes/${classId}/assignment/${assignmentId}/question/${questionId}/answer/upload-attachment`,
      body,
    );
  }

  deleteAnswerDocument(classId, assignmentId, questionId, url) {
    return this.generic.deleteWithOutId(
      `${this.controller}/classes/${classId}/assignment/${assignmentId}/question/${questionId}/answer/delete-attachment?fileUrl=${url}`,
    );
  }

  submitAssignment(classId, assignmentId, studentId, body) {
    return this.generic.create(
      `${this.controller}/classes/${classId}/assignment/${assignmentId}/submit?studentId=${studentId}`,
      body,
    );
  }

  getClassSettings(classId) {
    return this.generic.getData(
      `${this.controller}/classes/${classId}/class-setting`,
    );
  }

  getClassGradebook(classId, assignTerm, studentId) {
    const params = `?assignTerm=${assignTerm}&studentId=${studentId}`;
    return this.generic.getData(
      `${this.controller}/classes/${classId}/gradebook${params}`,
    );
  }

  getGradebook(assignTermId, studentId) {
    const query = `?assignTerm=${assignTermId}&studentId=${studentId}`;
    return this.generic.getData(`${this.controller}/gradebook${query}`);
  }

  getAssignTerms(academicYearId) {
    return this.generic.getData(
      `${this.controller}/assignterms/${academicYearId}`,
    );
  }
}
