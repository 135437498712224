import * as moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { GenericService } from '../generic/generic.service';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TeacherUserService {
  userTypeVisibility: BehaviorSubject<string> = new BehaviorSubject('');
  private readonly controller = 'teacher';
  private initialState = {
    data: [],
  };
  private classes$: BehaviorSubject<any> = new BehaviorSubject<any>(
    this.initialState,
  );
  private dataLoading = false;

  meetingTags = [
    {
      label: 'Academic Counseling',
      color: '#118000',
    },
    {
      label: 'Behaviour',
      color: '#FD0000',
    },
    {
      label: 'Parent Teacher Meeting',
      color: '#0081CC',
    },
    {
      label: 'Career Counseling',
      color: '#F708EF',
    },
    {
      label: 'Psychologist',
      color: '#FFC533',
    },
    {
      label: 'Other',
      color: '#8F7FE6',
    },
  ];

  constructor(private generic: GenericService) {}

  getFeeds(params) {
    const order = 'desc';
    return this.generic.getDataByParams(
      `${this.controller}/newsFeed`,
      params,
      order,
    );
  }
  getUpcomingEvents() {
    const todayDate = moment();
    const url = `fromDate=${todayDate.format('YYYY-MM-DD')}&limit=5`;

    return this.generic.getDataByParams(
      `${this.controller}/events`,
      url,
      'asc',
      'fromDate',
    );
  }

  getEventsForCalendar(dates?: any) {
    const firstDay =
      dates && dates.startDate
        ? moment(dates.startDate).startOf('month').format('YYYY-MM-DD')
        : moment().startOf('month').format('YYYY-MM-DD');
    const lastDay =
      dates && dates.endDate
        ? moment(dates.endDate).endOf('month').format('YYYY-MM-DD')
        : moment().endOf('month').format('YYYY-MM-DD');
    const url = `fromDate=${firstDay}&toDate=${lastDay}`;

    return this.generic.getDataByParams(
      `${this.controller}/events`,
      url,
      'asc',
      'fromDate',
    );
  }

  getPosts(params) {
    return this.generic.getDataByParams(
      `${this.controller}/posts`,
      params,
      'desc',
      'createdAt',
    );
  }

  getTimetable() {
    return this.generic.getData(`${this.controller}/timetable`);
  }
  getMeetingLogs(params?: any) {
    const order = 'desc';
    return this.generic.getDataByParams(
      `${this.controller}/meeting-logs`,
      params,
      order,
      'fromDate',
    );
  }

  getbyParams(params) {
    const order = 'asc';
    return this.generic.getDataByParams(
      `${this.controller}/events`,
      params,
      order,
      'fromDate',
    );
  }

  searchMeetingLogs(search, filter, order) {
    return this.generic.getDataBySearch(
      `${this.controller}/meeting-logs`,
      search,
      filter,
      order,
    );
  }

  getMeetingLogById(id) {
    return this.generic.getDataById(`${this.controller}/meeting-logs`, id);
  }

  deleteMeetingLog(id) {
    return this.generic.delete(`${this.controller}/meeting-logs`, id);
  }

  updateMeetingLog(body) {
    return this.generic.update(`${this.controller}/meeting-logs`, body);
  }

  postMeetingLog(body) {
    return this.generic.create(`${this.controller}/meeting-logs`, body);
  }

  getPolicies() {
    return this.generic.getData(`${this.controller}/policies`);
  }

  getTeacherProfile() {
    return this.generic.getData(this.controller);
  }

  updateTeacherProfile(body) {
    return this.generic.update(`${this.controller}`, body);
  }

  updateTeacherBranch(body) {
    return this.generic.update(`${this.controller}/branch`, body);
  }

  updateTeacherQualification(body) {
    return this.generic.update(`${this.controller}/qualification`, body);
  }

  updateTeacherExperience(body) {
    return this.generic.update(`${this.controller}/experience`, body);
  }

  addTeacherDocument(body) {
    return this.generic.upload(`${this.controller}/document`, body);
  }

  removeTeacherDocument(documentId) {
    return this.generic.delete(`${this.controller}/document`, documentId);
  }

  updateTeacherProfileImage(body) {
    return this.generic.imageUpload(`${this.controller}/profile-image`, body);
  }

  getClasses(param): Observable<any> {
    if (
      this.classes$.getValue() &&
      this.classes$.getValue() !== this.initialState
    ) {
      return this.classes$.asObservable();
    } else if (!this.dataLoading) {
      return this.set(param);
    } else {
      return this.classes$.asObservable();
    }
  }

  private set(param): Observable<any> {
    this.dataLoading = true;
    const order = 'desc';
    const sort = 'createdAt';
    return this.generic
      .getDataByParams(`${this.controller}/classes`, param, order, sort)
      .pipe(
        tap((response) => {
          this.classes$.next(response);
          this.dataLoading = false;
        }),
      );
  }

  filterClasses(param) {
    const order = 'desc';
    const sort = 'createdAt';
    return this.generic.getDataByParams(
      `${this.controller}/classes`,
      param,
      order,
      sort,
    );
  }

  getClassInfoById(classId) {
    return this.generic.getDataById(`${this.controller}/classes`, classId);
  }

  getClassSettings(classId) {
    return this.generic.getData(
      `${this.controller}/classes/${classId}/class-setting`,
    );
  }

  getClassAssignments(classId: string) {
    return this.generic.getData(
      `${this.controller}/classes/${classId}/class-assignments`,
    );
  }

  getAssignClasses() {
    return this.generic.getData(`${this.controller}/assignclasses`);
  }

  getClassCurriculum(classId, search) {
    let queryParams = '';
    if (search != undefined) {
      queryParams = queryParams.concat(`?search=${search}`);
    }
    return this.generic.getData(
      `${this.controller}/classes/${classId}/curriculum${queryParams}`,
    );
  }

  getViewUnit(classId, curriculumId, unitId) {
    return this.generic.getData(
      `${this.controller}/classes/${classId}/curriculum/${curriculumId}/unit/${unitId}`,
    );
  }

  getViewLesson(classId, curriculumId, unitId, lessonPlanId) {
    return this.generic.getData(
      `${this.controller}/classes/${classId}/curriculum/${curriculumId}/unit/${unitId}/lesson-plan/${lessonPlanId}`,
    );
  }

  getAllClassLessons(classId, param) {
    return this.generic.getData(
      `${this.controller}/classes/${classId}/lesson-plan` + '?' + param,
    );
  }

  getClassLessonById(classId, lessonId) {
    return this.generic.getDataById(
      `${this.controller}/classes/${classId}/lesson-plan`,
      lessonId,
    );
  }

  addClassLesson(classId, body) {
    return this.generic.create(
      `${this.controller}/classes/${classId}/lesson-plan`,
      body,
    );
  }

  updateClassLesson(body, classId, lessonId) {
    return this.generic.update(
      `${this.controller}/classes/${classId}/lesson-plan/${lessonId}`,
      body,
    );
  }

  deleteClassLesson(classId, lessonId) {
    return this.generic.delete(
      `${this.controller}/classes/${classId}/lesson-plan`,
      lessonId,
    );
  }

  uploadDocumentClassLesson(classId, body) {
    return this.generic.imageUpload(
      `${this.controller}/classes/${classId}/lesson-plan/upload-attachment`,
      body,
    );
  }

  deleteDocumentCLassLesson(classId, url) {
    return this.generic.deleteWithOutId(
      `${this.controller}/classes/${classId}/lesson-plan/delete-attachment?fileUrl=${url}`,
    );
  }

  addToLessonBank(body: any) {
    return this.generic.create(
      `${this.controller}/class-lesson-plan-bank`,
      body,
    );
  }

  getLessonsFromBank(subject, grade) {
    return this.generic.getData(
      `${this.controller}/class-lesson-plan-bank?subject=${subject}&grade=${grade}`,
    );
  }

  getStream(classId, params, order = 'desc', sort = 'createdAt') {
    return this.generic.getDataByParams(
      `${this.controller}/classes/${classId}/stream`,
      params,
      order,
      sort,
    );
  }

  postStream(classId, body) {
    return this.generic.submitMultipartFormData(
      `${this.controller}/classes/${classId}/stream`,
      body,
    );
  }

  updateStream(classId, streamId, body) {
    return this.generic.patchMultipartFormData(
      `${this.controller}/classes/${classId}/stream/${streamId}`,
      body,
    );
  }

  getStreamById(classId, streamId) {
    return this.generic.getDataById(
      `${this.controller}/classes/${classId}/stream`,
      streamId,
    );
  }

  deleteMedia(classId, streamId, mediaId, mediaUrl) {
    return this.generic.deleteWithOutId(
      `${this.controller}/classes/${classId}/stream/${streamId}/media/${mediaId}?mediaUrl=${mediaUrl}`,
    );
  }

  deleteStream(classId, streamId) {
    return this.generic.delete(
      `${this.controller}/classes/${classId}/stream`,
      streamId,
    );
  }

  getAllTopics(classId) {
    return this.generic.getData(`${this.controller}/classes/${classId}/topic`);
  }

  createTopic(classId, body) {
    return this.generic.create(
      `${this.controller}/classes/${classId}/topic`,
      body,
    );
  }

  updateTopic(classId, topicId, body) {
    return this.generic.patch(
      `${this.controller}/classes/${classId}/topic/${topicId}`,
      body,
    );
  }

  deleteTopic(classId, topicId) {
    return this.generic.deleteWithOutId(
      `${this.controller}/classes/${classId}/topic/${topicId}`,
    );
  }

  createMaterial(classId, body) {
    return this.generic.create(
      `${this.controller}/classes/${classId}/material`,
      body,
    );
  }

  getByIdMaterial(classId, materialId) {
    return this.generic.getDataById(
      `${this.controller}/classes/${classId}/material`,
      materialId,
    );
  }

  updateMaterial(classId, materialId, body) {
    return this.generic.update(
      `${this.controller}/classes/${classId}/material/${materialId}`,
      body,
    );
  }

  deleteMaterial(classId, materialId) {
    return this.generic.deleteWithOutId(
      `${this.controller}/classes/${classId}/material/${materialId}`,
    );
  }

  uploadMaterialAttachment(classId, body) {
    return this.generic.imageUpload(
      `${this.controller}/classes/${classId}/material/upload-attachment`,
      body,
    );
  }

  deleteMaterialAttachment(classId, url) {
    return this.generic.deleteWithOutId(
      `${this.controller}/classes/${classId}/material/delete-attachment?fileUrl=${url}`,
    );
  }

  getClassWork(classId) {
    return this.generic.getData(
      `${this.controller}/classes/${classId}/classwork`,
    );
  }

  getClassWorkAssignments(classId) {
    return this.generic.getData(
      `${this.controller}/classes/${classId}/assignment`,
    );
  }

  createClassAssignment(classId, body) {
    return this.generic.create(
      `${this.controller}/classes/${classId}/assignment`,
      body,
    );
  }

  getByIdClassAssignment(classId, assignmentId) {
    return this.generic.getDataById(
      `${this.controller}/classes/${classId}/assignment`,
      assignmentId,
    );
  }

  updateClassAssignment(classId, assignmentId, body) {
    return this.generic.update(
      `${this.controller}/classes/${classId}/assignment/${assignmentId}`,
      body,
    );
  }

  deleteClassAssignment(classId, assignmentId) {
    return this.generic.deleteWithOutId(
      `${this.controller}/classes/${classId}/assignment/${assignmentId}`,
    );
  }

  uploadClassAssignmentAttachment(classId, body) {
    return this.generic.imageUpload(
      `${this.controller}/classes/${classId}/assignment/upload-attachment`,
      body,
    );
  }

  deleteClassAssignmentAttachment(classId, url) {
    return this.generic.deleteWithOutId(
      `${this.controller}/classes/${classId}/assignment/delete-attachment?fileUrl=${url}`,
    );
  }

  assignOnlineAssignment(classId, assignmentId, body) {
    return this.generic.patch(
      `${this.controller}/classes/${classId}/assignment/${assignmentId}/assign/online-assignment`,
      body,
    );
  }

  editAssignOnlineAssignment(classId, assignmentId, body) {
    return this.generic.patch(
      `${this.controller}/classes/${classId}/assignment/${assignmentId}/online-assignment`,
      body,
    );
  }

  assignOfflineAssignment(classId, assignmentId, body) {
    return this.generic.patch(
      `${this.controller}/classes/${classId}/assignment/${assignmentId}/assign/offline-assignment`,
      body,
    );
  }

  unAssignOnlineAssignment(classId, assignmentId, body) {
    return this.generic.patch(
      `${this.controller}/classes/${classId}/assignment/${assignmentId}/unassign/online-assignment`,
      body,
    );
  }

  unAssignOfflineAssignment(classId, assignmentId, body) {
    return this.generic.patch(
      `${this.controller}/classes/${classId}/assignment/${assignmentId}/unassign/offline-assignment`,
      body,
    );
  }

  addReaction(classId, streamId, body) {
    return this.generic.create(
      `${this.controller}/classes/${classId}/stream/${streamId}/reaction`,
      body,
    );
  }
}
